<template>
  <div>
    <ReportPage/>
  </div>
</template>

<script>
import ReportPage from "./components/ReportPage.vue"
export default {
  name: 'App',
  components: {
    ReportPage,
  },
  data() {
    return {}
  },
}
</script>

<style>
</style>
