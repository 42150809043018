import axios from "axios";

let baseURL = "https://smartstreetlight.tycg.gov.tw:10888/";
// let baseURL = "http://taoyuan-streetlight-backend-test.learnik.io:9885/";
export const api = axios.create({
    baseURL,
});

api.interceptors.request.use(
    (config) => {
        // config.headers["Content-Type"] = "application/json";
        config.headers["Access-Control-Allow-Origin"] = "*";
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
