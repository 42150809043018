/* eslint-disable */
// import { reactive } from "../js/vue.esm-browser.prod.min.js";
    
export const SetThousand = (val = 0) => {
  const num = +val || '';
  const parts = num.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
}

export const Delay = time => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('delay: ' + time);
    }, time);
  })
}

export const Uuid = () => {
  return Math.random().toString(16).slice(2)
}

export const OnResize = (fn = () => { }) => {
  // window resize
  const menuClick = Rx.Observable.fromEvent($('.open-close'), 'click')
  const resize = Rx.Observable.fromEvent(window, 'resize')
  Rx.Observable.merge(menuClick, resize)
    .debounceTime(300)
    .subscribe(fn)
}

export const Toast = params => {
  let bgColor="";
  switch (params.icon) {
    case 'success':
      bgColor = "#16a085";
      break;
    case 'error':
      bgColor = "#c0392b";
      break;
  
    default:
      break;
  }
  $.toast({
    heading: params.heading || '上傳成功',
    text: params.text || '',
    position: params.position || 'top-right',
    loader: false,
    bgColor: bgColor,
    icon: params.icon || 'success',
  });
}

export const SetTable = (table, options = {}) => {
  const {
    responsive = false,
    iDisplayLength = 10,
    order = [[0, "desc"]],
    pageingType = 'simple_numbers',
    columnDefs = [],
    searching = true,
    bLengthChange = true, 
    dom = 'lftipr',
  } = options;

  const dlang = {
    "sProcessing": "載入中...",
    "sLengthMenu": "每頁 _MENU_ 筆",
    "sZeroRecords": "沒有符合結果",
    "sInfo": "從 _START_ 到 _END_ /共 _TOTAL_ 筆",
    "sInfoEmpty":    "顯示第 0 至 0 項結果，共 0 項",
    "sInfoFiltered": "(過濾總筆數 _MAX_)",
    "sInfoPostFix": "",
    "sSearch": "搜尋:",
    "sUrl": "",
    "sEmptyTable": "無任何資料",
    "sLoadingRecords": "載入中...",
    "sInfoThousands": ",",
    "oPaginate": {
      "sFirst": "首頁",
      "sPrevious": "上頁",
      "sNext": "下頁",
      "sLast": "末頁",
      "sJump": "跳頁"
    },
    "oAria": {
      "sSortAscending": ": 以升序排列此列",
      "sSortDescending": ": 以降序排列此列"
    }
  }

  // reset tooltip
  $('[data-toggle="tooltip"]').tooltip('enable')
  
  return $(table).DataTable({
    responsive: responsive,
    language: dlang,
    stateSave: false,
    stateDuration: 60 * 60,
    searching: searching,
    iDisplayLength: iDisplayLength,
    order: order,
    pagingType: pageingType,
    bLengthChange: bLengthChange,
    dom: dom,
    columnDefs: [
      ...columnDefs,
      { className: 'text-center', orderable: false, targets: -1 },
    ]
  });
}

export const GetCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}